import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import { Text, Button } from 'theme-ui'
import { Link } from 'gatsby'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Let's Connect"
          subheader='Weightloss Boston, The Sadkhin Complex'          
        />
        <Text><b>556 Cambridge Street, Boston/Allston MA 02134 (We’ve moved!)</b></Text>
        <Text>Phone: (617) 277-8844</Text><br/>
        <Button as={Link} to='https://docmein.com/app/page/77293722292565792/secure/home'>
        Start Loosing Weight!
      </Button><br/><br/>
        <Text>To get your free consultation  <a href="https://docmein.com/app/page/77293722292565792/secure/home">book an appointment</a> online and bring a print out of the confirmation email when you come to the office. Please use the form below for inquires about the program</Text>
        <Divider />
        <ContactForm />

      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
