import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Our Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        We take our commitment. The methods we use are non-invasive, natural and fast-moving. If your weight loss goals could not be met through any other clinical systems, diets or even surgery you still have a healthy and effective alternative that you simply must try. 
      
      </Text>
    </Card>
  </Section>
)

export default Commitment
